#phone-animation {
    animation: phone-icon 3000ms infinite;
}

@keyframes phone-icon {
    0% { transform: translate3d(0em, 0, 0); }
    2% { transform: translate3d(0.03em, 0, 0) scale(1.1) ; }
    4% { transform: translate3d(-0.03em, 0, 0) scale(1.1); }
    6% { transform: translate3d(0.03em, 0, 0) scale(1.2) ; }
    8% { transform: translate3d(-0.03em, 0, 0) scale(1.2) ; }
    10% { transform: translate3d(0.03em, 0, 0) scale(1.3) ; }
    12% { transform: translate3d(-0.03em, 0, 0) scale(1.3) ; }
    14% { transform: translate3d(0.03em, 0, 0) scale(1.3) ; }
    16% { transform: translate3d(-0.03em, 0, 0) scale(1.4) ; }
    18% { transform: translate3d(0.03em, 0, 0) scale(1.4) ; }
    20% { transform: translate3d(-0.03em, 0, 0) scale(1.4) ; }
    22% { transform: translate3d(0.03em, 0, 0) scale(1.4) ; }
    24% { transform: translate3d(-0.03em, 0, 0) scale(1.4) ; }
    26% { transform: translate3d(0.03em, 0, 0) scale(1.4) ; }
    28% { transform: translate3d(-0.03em, 0, 0) scale(1.4); }
    30% { transform: translate3d(0.03em, 0, 0) scale(1.4) ; }
    32% { transform: translate3d(-0.03em, 0, 0) scale(1.3) ; }
    34% { transform: translate3d(0.03em, 0, 0) scale(1.3) ; }
    36% { transform: translate3d(-0.03em, 0, 0) scale(1.3) ; }
    38% { transform: translate3d(0.03em, 0, 0) scale(1.2) ; }
    40% { transform: translate3d(-0.03em, 0, 0) scale(1.2) ; }
    42% { transform: translate3d(0.03em, 0, 0) scale(1.1) ; }
    44% { transform: translate3d(-0.03em, 0, 0) scale(1.1) ; }
    46% { transform: translate3d(0em, 0, 0) ; }
  }